/* eslint-disable react/no-unknown-property */
import React from 'react';

import { theme } from 'styles/theme';

export function ArrowBack() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="ArrowBackFilled">
        <path
          id="Vector"
          d="M15 8.25H5.8725L10.065 4.0575L9 3L3 9L9 15L10.0575 13.9425L5.8725 9.75H15V8.25Z"
          fill="black"
          fillOpacity="0.87"
        />
      </g>
    </svg>
  );
}

export function ArrowFoward({ color = '#FFFFFF' }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99967 3.33301L8.82467 4.50801L13.4747 9.16634H3.33301V10.833H13.4747L8.82467 15.4913L9.99967 16.6663L16.6663 9.99967L9.99967 3.33301Z"
        fill={color}
      />
    </svg>
  );
}

export function Star() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM15.22 17.39L12 15.45L8.78 17.39C8.4 17.62 7.93 17.28 8.03 16.85L8.88 13.19L6.05 10.74C5.72 10.45 5.9 9.9 6.34 9.86L10.08 9.54L11.54 6.09C11.71 5.68 12.29 5.68 12.46 6.09L13.92 9.53L17.66 9.85C18.1 9.89 18.28 10.44 17.94 10.73L15.11 13.18L15.96 16.85C16.06 17.28 15.6 17.62 15.22 17.39Z"
        fill="#12B152"
      />
    </svg>
  );
}

export function Bell() {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.5762 21.6843C27.9253 20.9243 27.6296 20.2656 27.6296 19.1466V18.7661C27.6296 17.3079 27.2939 16.3684 26.5643 15.4288C25.4397 13.9697 23.5464 13.0903 21.6929 13.0903H21.6142C19.7997 13.0903 17.9658 13.9294 16.8217 15.3292C16.0522 16.2876 15.6776 17.2675 15.6776 18.7661V19.1466C15.6776 20.2656 15.4013 20.9243 14.7309 21.6843C14.2377 22.2443 14.0801 22.9639 14.0801 23.7428C14.0801 24.5226 14.336 25.2612 14.8496 25.8615C15.5199 26.5812 16.4666 27.0406 17.4336 27.1205C18.8336 27.2802 20.2336 27.3403 21.654 27.3403C23.0736 27.3403 24.4736 27.2398 25.8744 27.1205C26.8405 27.0406 27.7872 26.5812 28.4575 25.8615C28.9703 25.2612 29.2271 24.5226 29.2271 23.7428C29.2271 22.9639 29.0694 22.2443 28.5762 21.6843Z"
        fill="#60CDC7"
      />
      <path
        d="M23.4432 28.441C22.9978 28.3458 20.2836 28.3458 19.8382 28.441C19.4574 28.5289 19.0457 28.7335 19.0457 29.1822C19.0678 29.6103 19.3184 29.988 19.6655 30.2276L19.6646 30.2285C20.1136 30.5785 20.6405 30.801 21.1922 30.8809C21.4862 30.9213 21.7855 30.9195 22.0901 30.8809C22.6409 30.801 23.1678 30.5785 23.6168 30.2285L23.6159 30.2276C23.963 29.988 24.2136 29.6103 24.2357 29.1822C24.2357 28.7335 23.824 28.5289 23.4432 28.441Z"
        fill="#1861A5"
      />
      <circle cx="26.1001" cy="15.1001" r="2" fill="#1861A5" />
    </svg>
  );
}

export function Download({ color = '#0F3260' }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="DownloadRounded">
        <path
          id="Vector"
          d="M12.4425 6.75H11.25V3C11.25 2.5875 10.9125 2.25 10.5 2.25H7.5C7.0875 2.25 6.75 2.5875 6.75 3V6.75H5.5575C4.89 6.75 4.5525 7.56 5.025 8.0325L8.4675 11.475C8.76 11.7675 9.2325 11.7675 9.525 11.475L12.9675 8.0325C13.44 7.56 13.11 6.75 12.4425 6.75ZM3.75 14.25C3.75 14.6625 4.0875 15 4.5 15H13.5C13.9125 15 14.25 14.6625 14.25 14.25C14.25 13.8375 13.9125 13.5 13.5 13.5H4.5C4.0875 13.5 3.75 13.8375 3.75 14.25Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export function Upload({ color = '#ECF5FF' }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 15.9997H14C14.55 15.9997 15 15.5497 15 14.9997V9.99973H16.59C17.48 9.99973 17.93 8.91973 17.3 8.28973L12.71 3.69973C12.32 3.30973 11.69 3.30973 11.3 3.69973L6.71 8.28973C6.08 8.91973 6.52 9.99973 7.41 9.99973H9V14.9997C9 15.5497 9.45 15.9997 10 15.9997ZM6 17.9997H18C18.55 17.9997 19 18.4497 19 18.9997C19 19.5497 18.55 19.9997 18 19.9997H6C5.45 19.9997 5 19.5497 5 18.9997C5 18.4497 5.45 17.9997 6 17.9997Z"
        fill={color}
      />
    </svg>
  );
}

export function Plus() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="AddCircleOutlineRounded">
        <path
          id="Vector"
          d="M12 7C11.45 7 11 7.45 11 8V11H8C7.45 11 7 11.45 7 12C7 12.55 7.45 13 8 13H11V16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16V13H16C16.55 13 17 12.55 17 12C17 11.45 16.55 11 16 11H13V8C13 7.45 12.55 7 12 7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
          fill="#ECF5FF"
        />
      </g>
    </svg>
  );
}

export function Money(w = 24, h = 25, color = '#003F71') {
  return (
    <svg width={w} height={h} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8 11.4C9.53 10.81 8.8 10.2 8.8 9.25C8.8 8.16 9.81 7.4 11.5 7.4C12.92 7.4 13.63 7.94 13.89 8.8C14.01 9.2 14.34 9.5 14.76 9.5H15.06C15.72 9.5 16.19 8.85 15.96 8.23C15.54 7.05 14.56 6.07 13 5.69V5C13 4.17 12.33 3.5 11.5 3.5C10.67 3.5 10 4.17 10 5V5.66C8.06 6.08 6.5 7.34 6.5 9.27C6.5 11.58 8.41 12.73 11.2 13.4C13.7 14 14.2 14.88 14.2 15.81C14.2 16.5 13.71 17.6 11.5 17.6C9.85 17.6 9 17.01 8.67 16.17C8.52 15.78 8.18 15.5 7.77 15.5H7.49C6.82 15.5 6.35 16.18 6.6 16.8C7.17 18.19 8.5 19.01 10 19.33V20C10 20.83 10.67 21.5 11.5 21.5C12.33 21.5 13 20.83 13 20V19.35C14.95 18.98 16.5 17.85 16.5 15.8C16.5 12.96 14.07 11.99 11.8 11.4Z"
        fill={color}
      />
    </svg>
  );
}

export function Dollar() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.83366 9.08333C7.94199 8.59167 7.33366 8.08333 7.33366 7.29167C7.33366 6.38333 8.17533 5.75 9.58366 5.75C10.767 5.75 11.3587 6.2 11.5753 6.91667C11.6753 7.25 11.9503 7.5 12.3003 7.5H12.5503C13.1003 7.5 13.492 6.95833 13.3003 6.44167C12.9503 5.45833 12.1337 4.64167 10.8337 4.325V3.75C10.8337 3.05833 10.2753 2.5 9.58366 2.5C8.89199 2.5 8.33366 3.05833 8.33366 3.75V4.3C6.71699 4.65 5.41699 5.7 5.41699 7.30833C5.41699 9.23333 7.00866 10.1917 9.33366 10.75C11.417 11.25 11.8337 11.9833 11.8337 12.7583C11.8337 13.3333 11.4253 14.25 9.58366 14.25C8.20866 14.25 7.50033 13.7583 7.22533 13.0583C7.10033 12.7333 6.81699 12.5 6.47533 12.5H6.24199C5.68366 12.5 5.29199 13.0667 5.50033 13.5833C5.97533 14.7417 7.08366 15.425 8.33366 15.6917V16.25C8.33366 16.9417 8.89199 17.5 9.58366 17.5C10.2753 17.5 10.8337 16.9417 10.8337 16.25V15.7083C12.4587 15.4 13.7503 14.4583 13.7503 12.75C13.7503 10.3833 11.7253 9.575 9.83366 9.08333Z"
        fill="#003F71"
      />
    </svg>
  );
}

export function Location() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0002 1.66699C6.50016 1.66699 3.3335 4.35033 3.3335 8.50033C3.3335 11.1503 5.37516 14.267 9.45016 17.8587C9.76683 18.1337 10.2418 18.1337 10.5585 17.8587C14.6252 14.267 16.6668 11.1503 16.6668 8.50033C16.6668 4.35033 13.5002 1.66699 10.0002 1.66699ZM10.0002 10.0003C9.0835 10.0003 8.3335 9.25033 8.3335 8.33366C8.3335 7.41699 9.0835 6.66699 10.0002 6.66699C10.9168 6.66699 11.6668 7.41699 11.6668 8.33366C11.6668 9.25033 10.9168 10.0003 10.0002 10.0003Z"
        fill="#003F71"
      />
    </svg>
  );
}

export function Car() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.92 6.51C18.72 5.92 18.16 5.5 17.5 5.5H6.5C5.84 5.5 5.29 5.92 5.08 6.51L3 12.5V20C3 20.83 3.67 21.5 4.5 21.5C5.33 21.5 6 20.83 6 20V19.5H18V20C18 20.82 18.67 21.5 19.5 21.5C20.32 21.5 21 20.83 21 20V12.5L18.92 6.51ZM7.5 16.5C6.67 16.5 6 15.83 6 15C6 14.17 6.67 13.5 7.5 13.5C8.33 13.5 9 14.17 9 15C9 15.83 8.33 16.5 7.5 16.5ZM16.5 16.5C15.67 16.5 15 15.83 15 15C15 14.17 15.67 13.5 16.5 13.5C17.33 13.5 18 14.17 18 15C18 15.83 17.33 16.5 16.5 16.5ZM5.81 10.5L6.85 7.5H17.14L18.18 10.5H5.81Z"
        fill="#003F71"
      />
    </svg>
  );
}

export function Factory() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.667 8.83373V7.56706C11.667 6.97539 11.0753 6.57539 10.5253 6.79206L7.50033 8.00039V7.60039C7.50033 7.00039 6.89199 6.59206 6.34199 6.83373L2.67533 8.40039C2.06699 8.66706 1.66699 9.26706 1.66699 9.93373V17.1671C1.66699 18.0837 2.41699 18.8337 3.33366 18.8337H16.667C17.5837 18.8337 18.3337 18.0837 18.3337 17.1671V8.83373H11.667ZM7.50033 14.6671C7.50033 15.1254 7.12533 15.5004 6.66699 15.5004C6.20866 15.5004 5.83366 15.1254 5.83366 14.6671V13.0004C5.83366 12.5421 6.20866 12.1671 6.66699 12.1671C7.12533 12.1671 7.50033 12.5421 7.50033 13.0004V14.6671ZM10.8337 14.6671C10.8337 15.1254 10.4587 15.5004 10.0003 15.5004C9.54199 15.5004 9.16699 15.1254 9.16699 14.6671V13.0004C9.16699 12.5421 9.54199 12.1671 10.0003 12.1671C10.4587 12.1671 10.8337 12.5421 10.8337 13.0004V14.6671ZM14.167 14.6671C14.167 15.1254 13.792 15.5004 13.3337 15.5004C12.8753 15.5004 12.5003 15.1254 12.5003 14.6671V13.0004C12.5003 12.5421 12.8753 12.1671 13.3337 12.1671C13.792 12.1671 14.167 12.5421 14.167 13.0004V14.6671Z"
        fill="#003F71"
      />
      <path
        d="M16.7673 2.16699H15.7423C15.3173 2.16699 14.9673 2.48366 14.9173 2.90033L14.334 7.58366H18.1673L17.5923 2.90033C17.5423 2.48366 17.184 2.16699 16.7673 2.16699Z"
        fill="#003F71"
      />
    </svg>
  );
}

export function CircleArrowRight() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1970_490)">
        <rect width="24" height="24" rx="12" fill="white" />
        <path
          d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997C10.91 5.08997 10.91 5.71997 11.3 6.10997L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
          fill="#003F71"
        />
      </g>
      <defs>
        <clipPath id="clip0_1970_490">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface CircleIconProps {
  backgroundColor?: string;
  color?: string;
}

export function CircleX({ backgroundColor = 'white', color = '#D91717' }: CircleIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1970_675)">
        <rect width="24" height="24" rx="12" fill={backgroundColor} />
        <path
          d="M18.3002 5.71C17.9102 5.32 17.2802 5.32 16.8902 5.71L12.0002 10.59L7.11022 5.7C6.72022 5.31 6.09021 5.31 5.70021 5.7C5.31021 6.09 5.31021 6.72 5.70021 7.11L10.5902 12L5.70021 16.89C5.31021 17.28 5.31021 17.91 5.70021 18.3C6.09021 18.69 6.72022 18.69 7.11022 18.3L12.0002 13.41L16.8902 18.3C17.2802 18.69 17.9102 18.69 18.3002 18.3C18.6902 17.91 18.6902 17.28 18.3002 16.89L13.4102 12L18.3002 7.11C18.6802 6.73 18.6802 6.09 18.3002 5.71Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1970_675">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CircleCheck({ backgroundColor = 'white', color = '#12B152' }: CircleIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1970_875)">
        <rect width="24" height="24" rx="12" fill={backgroundColor} />
        <path
          d="M9.00014 16.17L5.53014 12.7C5.14014 12.31 4.51014 12.31 4.12014 12.7C3.73014 13.09 3.73014 13.72 4.12014 14.11L8.30014 18.29C8.69014 18.68 9.32014 18.68 9.71014 18.29L20.2901 7.71001C20.6801 7.32001 20.6801 6.69001 20.2901 6.30001C19.9001 5.91001 19.2701 5.91001 18.8801 6.30001L9.00014 16.17Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1970_875">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ReturnProps {
  color?: string;
}

export function Return({ color = '#0085D4' }: ReturnProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0005 7.99998V11H5.83047L8.71047 8.11998C9.10047 7.72998 9.10047 7.09998 8.71047 6.70998C8.32047 6.31998 7.69047 6.31998 7.30047 6.70998L2.71047 11.3C2.32047 11.69 2.32047 12.32 2.71047 12.71L7.30047 17.3C7.69047 17.69 8.32047 17.69 8.71047 17.3C9.10047 16.91 9.10047 16.28 8.71047 15.89L5.83047 13H20.0005C20.5505 13 21.0005 12.55 21.0005 12V7.99998C21.0005 7.44998 20.5505 6.99998 20.0005 6.99998C19.4505 6.99998 19.0005 7.44998 19.0005 7.99998Z"
        fill={color}
      />
    </svg>
  );
}

interface EditProps {
  color?: string;
}

export function Edit({ color = theme.palette.error.main }: EditProps) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 15.46V18.5C0 18.78 0.22 19 0.5 19H3.54C3.67 19 3.8 18.95 3.89 18.85L14.81 7.94L11.06 4.19L0.15 15.1C0.0500001 15.2 0 15.32 0 15.46ZM17.71 5.04C18.1 4.65 18.1 4.02 17.71 3.63L15.37 1.29C14.98 0.899998 14.35 0.899998 13.96 1.29L12.13 3.12L15.88 6.87L17.71 5.04Z"
        fill={color}
      />
    </svg>
  );
}

export function EditWithEye() {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2506 2.42953L15.9057 5.08468L7.73468 13.2557L5.07953 10.6006L13.2506 2.42953ZM4.5075 11.2723L7.16265 13.9275L4.09668 14.3383L4.5075 11.2723ZM15.1742 0.511534C15.3755 0.310233 15.6741 0.28541 15.9071 0.518492C16.6485 1.25981 17.6102 2.22159 17.8406 2.45199C18.071 2.68239 18.0369 2.95907 17.8293 3.16668C17.6217 3.3743 16.4243 4.57167 16.4243 4.57167L13.7692 1.91652C13.7692 1.91652 14.9729 0.712834 15.1742 0.511534Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2869 1.73791C10.9488 0.860083 9.19883 0.0392616 7.15512 0H7.14614H7.13716C4.2219 0.0560241 1.90126 1.70344 0.524439 2.83704C0.458591 2.89105 0.00763314 3.24082 0.000150431 3.688C-0.00533689 4.00295 0.139828 4.23562 0.348845 4.43045C1.40365 5.46265 3.62782 7.24796 6.54672 7.47809L12.2869 1.73791ZM9.98449 4.04031C9.99379 3.94502 9.99855 3.84837 9.99855 3.75059C9.99855 2.15264 8.72749 0.856008 7.14614 0.845913C5.56879 0.856008 4.29323 2.15264 4.29323 3.75059C4.29323 5.34853 5.56879 6.64516 7.14614 6.65576C7.22496 6.65521 7.303 6.65146 7.38016 6.64465L9.98449 4.04031ZM7.15512 2.62152C7.15213 2.62102 7.14913 2.62102 7.14614 2.62102H7.13716V2.62152C6.53356 2.63667 6.04818 3.13634 6.04818 3.75059C6.04818 4.36483 6.53306 4.86501 7.13716 4.87965V4.88066C7.14016 4.88066 7.14315 4.87965 7.14614 4.87965C7.14864 4.87965 7.15163 4.88066 7.15512 4.88066V4.87965C7.76322 4.86501 8.2441 4.36483 8.2441 3.75059C8.2441 3.13634 7.76322 2.63667 7.15512 2.62152Z"
        fill="white"
      />
    </svg>
  );
}

interface RemoveProps {
  color?: string;
}

export function Remove({ color = theme.palette.error.main }: RemoveProps) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3002 0.709971C12.9102 0.319971 12.2802 0.319971 11.8902 0.709971L7.00022 5.58997L2.11022 0.699971C1.72022 0.309971 1.09021 0.309971 0.700215 0.699971C0.310215 1.08997 0.310215 1.71997 0.700215 2.10997L5.59022 6.99997L0.700215 11.89C0.310215 12.28 0.310215 12.91 0.700215 13.3C1.09021 13.69 1.72022 13.69 2.11022 13.3L7.00022 8.40997L11.8902 13.3C12.2802 13.69 12.9102 13.69 13.3002 13.3C13.6902 12.91 13.6902 12.28 13.3002 11.89L8.41021 6.99997L13.3002 2.10997C13.6802 1.72997 13.6802 1.08997 13.3002 0.709971Z"
        fill={color}
      />
    </svg>
  );
}

export function Search() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.7559 14.2549H14.9659L14.6859 13.9849C15.6659 12.8449 16.2559 11.3649 16.2559 9.75488C16.2559 6.16488 13.3459 3.25488 9.75586 3.25488C6.16586 3.25488 3.25586 6.16488 3.25586 9.75488C3.25586 13.3449 6.16586 16.2549 9.75586 16.2549C11.3659 16.2549 12.8459 15.6649 13.9859 14.6849L14.2559 14.9649V15.7549L19.2559 20.7449L20.7459 19.2549L15.7559 14.2549ZM9.75586 14.2549C7.26586 14.2549 5.25586 12.2449 5.25586 9.75488C5.25586 7.26488 7.26586 5.25488 9.75586 5.25488C12.2459 5.25488 14.2559 7.26488 14.2559 9.75488C14.2559 12.2449 12.2459 14.2549 9.75586 14.2549Z"
        fill="#003F71"
      />
    </svg>
  );
}

export function Excel() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M11.9789 17.3268V1H10.6607C7.27479 1.61172 3.8981 2.17756 0.5 2.76481V18.2077C3.84305 18.798 7.22891 19.3883 10.5934 20H11.9697V17.3268H11.9789ZM6.99952 13.9379C6.57779 13.0361 6.20811 12.1109 5.89231 11.1668C5.58645 12.0629 5.16436 12.9071 4.81874 13.7849C4.33548 13.7849 3.85222 13.7574 3.36896 13.7391C3.9348 12.6288 4.48229 11.5093 5.06648 10.4082C4.57099 9.27044 4.0235 8.15711 3.51272 7.02849L4.97473 6.94591C5.302 7.80843 5.66291 8.66178 5.93513 9.54572C6.24099 8.60673 6.66001 7.71056 7.03316 6.81745C7.53273 6.78279 8.03332 6.7522 8.53493 6.72569C7.94768 7.93282 7.35533 9.143 6.75789 10.3562C7.36961 11.5797 7.98133 12.8184 8.59305 14.0541C8.06085 14.0113 7.53171 13.9776 6.99952 13.9379Z"
        fill="white"
      />
      <path
        d="M12.5352 3.11621V4.28765H14.875V6.01576H12.5352V6.96392H14.875V8.69203H12.5352V9.6402H14.875V11.3652H12.5352V12.3134H14.875V14.0262H12.5352V15.0294H14.875V16.7025H12.5352V17.8739H20.616V3.11621H12.5352ZM18.8328 16.7147H15.4316V15.0417H18.8328V16.7147ZM18.8328 14.0385H15.4316V12.3104H18.8328V14.0385ZM18.8328 11.3622H15.4316V9.63714H18.8328V11.3622ZM18.8328 8.68591H15.4316V6.96087H18.8328V8.68591ZM18.8328 6.00964H15.4316V4.28765H18.8328V6.00964Z"
        fill="white"
      />
    </svg>
  );
}

export function More() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}

export function Logout() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10.79 16.29C11.18 16.68 11.81 16.68 12.2 16.29L15.79 12.7C16.18 12.31 16.18 11.68 15.79 11.29L12.2 7.7C11.81 7.31 11.18 7.31 10.79 7.7C10.4 8.09 10.4 8.72 10.79 9.11L12.67 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13H12.67L10.79 14.88C10.4 15.27 10.41 15.91 10.79 16.29ZM19 3H5C3.89 3 3 3.9 3 5V8C3 8.55 3.45 9 4 9C4.55 9 5 8.55 5 8V6C5 5.45 5.45 5 6 5H18C18.55 5 19 5.45 19 6V18C19 18.55 18.55 19 18 19H6C5.45 19 5 18.55 5 18V16C5 15.45 4.55 15 4 15C3.45 15 3 15.45 3 16V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
        fill="#D91717"
      />
    </svg>
  );
}

export function BrazilFlag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-br" viewBox="0 0 640 480">
      <g strokeWidth="1pt">
        <path fill="#229e45" fillRule="evenodd" d="M0 0h640v480H0z" />
        <path
          fill="#f8e509"
          fillRule="evenodd"
          d="m321.4 436 301.5-195.7L319.6 44 17.1 240.7 321.4 436z"
        />
        <path
          fill="#2b49a3"
          fillRule="evenodd"
          d="M452.8 240c0 70.3-57.1 127.3-127.6 127.3A127.4 127.4 0 1 1 452.8 240z"
        />
        <path
          fill="#ffffef"
          fillRule="evenodd"
          d="m283.3 316.3-4-2.3-4 2 .9-4.5-3.2-3.4 4.5-.5 2.2-4 1.9 4.2 4.4.8-3.3 3m86 26.3-3.9-2.3-4 2 .8-4.5-3.1-3.3 4.5-.5 2.1-4.1 2 4.2 4.4.8-3.4 3.1m-36.2-30-3.4-2-3.5 1.8.8-3.9-2.8-2.9 4-.4 1.8-3.6 1.6 3.7 3.9.7-3 2.7m87-8.5-3.4-2-3.5 1.8.8-3.9-2.7-2.8 3.9-.4 1.8-3.5 1.6 3.6 3.8.7-2.9 2.6m-87.3-22-4-2.2-4 2 .8-4.6-3.1-3.3 4.5-.5 2.1-4.1 2 4.2 4.4.8-3.4 3.2m-104.6-35-4-2.2-4 2 1-4.6-3.3-3.3 4.6-.5 2-4.1 2 4.2 4.4.8-3.3 3.1m13.3 57.2-4-2.3-4 2 .9-4.5-3.2-3.3 4.5-.6 2.1-4 2 4.2 4.4.8-3.3 3.1m132-67.3-3.6-2-3.6 1.8.8-4-2.8-3 4-.5 1.9-3.6 1.7 3.8 4 .7-3 2.7m-6.7 38.3-2.7-1.6-2.9 1.4.6-3.2-2.2-2.3 3.2-.4 1.5-2.8 1.3 3 3 .5-2.2 2.2m-142.2 50.4-2.7-1.5-2.7 1.3.6-3-2.1-2.2 3-.4 1.4-2.7 1.3 2.8 3 .6-2.3 2M419 299.8l-2.2-1.1-2.2 1 .5-2.3-1.7-1.6 2.4-.3 1.2-2 1 2 2.5.5-1.9 1.5"
        />
        <path
          fill="#ffffef"
          fillRule="evenodd"
          d="m219.3 287.6-2.7-1.5-2.7 1.3.6-3-2.1-2.2 3-.4 1.4-2.7 1.3 2.8 3 .6-2.3 2"
        />
        <path
          fill="#ffffef"
          fillRule="evenodd"
          d="m219.3 287.6-2.7-1.5-2.7 1.3.6-3-2.1-2.2 3-.4 1.4-2.7 1.3 2.8 3 .6-2.3 2m42.3 3-2.6-1.4-2.7 1.3.6-3-2.1-2.2 3-.4 1.4-2.7 1.3 2.8 3 .5-2.3 2.1m-4.8 17-2.6-1.5-2.7 1.4.6-3-2.1-2.3 3-.4 1.4-2.7 1.3 2.8 3 .6-2.3 2m87.4-22.2-2.6-1.6-2.8 1.4.6-3-2-2.3 3-.3 1.4-2.7 1.2 2.8 3 .5-2.2 2.1m-25.1 3-2.7-1.5-2.7 1.4.6-3-2-2.3 3-.3 1.4-2.8 1.2 2.9 3 .5-2.2 2.1m-68.8-5.8-1.7-1-1.7.8.4-1.9-1.3-1.4 1.9-.2.8-1.7.8 1.8 1.9.3-1.4 1.3m167.8 45.4-2.6-1.5-2.7 1.4.6-3-2.1-2.3 3-.4 1.4-2.7 1.3 2.8 3 .6-2.3 2m-20.8 6-2.2-1.4-2.3 1.2.5-2.6-1.7-1.8 2.5-.3 1.2-2.3 1 2.4 2.5.4-1.9 1.8m10.4 2.3-2-1.2-2.1 1 .4-2.3-1.6-1.7 2.3-.3 1.1-2 1 2 2.3.5-1.7 1.6m29.1-22.8-2-1-2 1 .5-2.3-1.6-1.7 2.3-.3 1-2 1 2.1 2.1.4-1.6 1.6m-38.8 41.8-2.5-1.4-2.7 1.2.6-2.8-2-2 3-.3 1.3-2.5 1.2 2.6 3 .5-2.3 1.9m.6 14.2-2.4-1.4-2.4 1.3.6-2.8-1.9-2 2.7-.4 1.2-2.5 1.1 2.6 2.7.5-2 2m-19-23.1-1.9-1.2-2 1 .4-2.2-1.5-1.7 2.2-.2 1-2 1 2 2.2.4-1.6 1.6m-17.8 2.3-2-1.2-2 1 .5-2.2-1.6-1.7 2.3-.2 1-2 1 2 2.1.4-1.6 1.6m-30.4-24.6-2-1.1-2 1 .5-2.3-1.6-1.6 2.2-.3 1-2 1 2 2.2.5-1.6 1.5m3.7 57-1.6-.9-1.8.9.4-2-1.3-1.4 1.9-.2.9-1.7.8 1.8 1.9.3-1.4 1.3m-46.2-86.6-4-2.3-4 2 .9-4.5-3.2-3.3 4.5-.6 2.2-4 1.9 4.2 4.4.8-3.3 3.1"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M444.4 285.8a124.6 124.6 0 0 0 5.8-19.8c-67.8-59.5-143.3-90-238.7-83.7a124.5 124.5 0 0 0-8.5 20.9c113-10.8 196 39.2 241.4 82.6z"
        />
        <path
          fill="#309e3a"
          d="m414 252.4 2.3 1.3a3.4 3.4 0 0 0-.3 2.2 3 3 0 0 0 1.4 1.7c.7.5 1.4.8 2 .7.6 0 1-.3 1.3-.7a1.3 1.3 0 0 0 .2-.9 2.3 2.3 0 0 0-.5-1c-.2-.3-.7-1-1.5-1.8a7.7 7.7 0 0 1-1.8-3 3.7 3.7 0 0 1 2-4.4 3.8 3.8 0 0 1 2.3-.2 7 7 0 0 1 2.6 1.2c1.4 1 2.3 2 2.6 3.2a4.1 4.1 0 0 1-.6 3.3l-2.4-1.5c.3-.6.4-1.2.2-1.7-.1-.5-.5-1-1.2-1.4a3.2 3.2 0 0 0-1.8-.7 1 1 0 0 0-.9.5c-.2.3-.2.6-.1 1s.6 1.2 1.6 2.2c1 1 1.6 1.9 2 2.5a3.9 3.9 0 0 1-.3 4.2 4.1 4.1 0 0 1-1.9 1.5 4 4 0 0 1-2.4.3c-.9-.2-1.8-.6-2.8-1.3-1.5-1-2.4-2.1-2.7-3.3a5.4 5.4 0 0 1 .6-4zm-11.6-7.6 2.5 1.3a3.4 3.4 0 0 0-.2 2.2 3 3 0 0 0 1.4 1.6c.8.5 1.4.7 2 .6.6 0 1-.3 1.3-.8a1.3 1.3 0 0 0 .2-.8c0-.3-.2-.7-.5-1a34.6 34.6 0 0 0-1.6-1.8c-1.1-1.1-1.8-2-2-2.8a3.7 3.7 0 0 1 .4-3.1 3.6 3.6 0 0 1 1.6-1.4 3.8 3.8 0 0 1 2.2-.3 7 7 0 0 1 2.6 1c1.5 1 2.4 2 2.7 3.1a4.1 4.1 0 0 1-.4 3.4l-2.5-1.4c.3-.7.4-1.2.2-1.7s-.6-1-1.3-1.4a3.2 3.2 0 0 0-1.9-.6 1 1 0 0 0-.8.5c-.2.3-.2.6-.1 1s.7 1.2 1.7 2.2c1 1 1.7 1.8 2 2.4a3.9 3.9 0 0 1 0 4.2 4.2 4.2 0 0 1-1.8 1.6 4 4 0 0 1-2.4.3 8 8 0 0 1-2.9-1.1 6 6 0 0 1-2.8-3.2 5.4 5.4 0 0 1 .4-4zm-14.2-3.8 7.3-12 8.8 5.5-1.2 2-6.4-4-1.6 2.7 6 3.7-1.3 2-6-3.7-2 3.3 6.7 4-1.2 2-9-5.5zm-20.7-17 1.1-2 5.4 2.7-2.5 5c-.8.2-1.8.3-3 .2a9.4 9.4 0 0 1-3.3-1 7.7 7.7 0 0 1-3-2.6 6 6 0 0 1-1-3.5 8.6 8.6 0 0 1 1-3.7 8 8 0 0 1 2.6-3 6.2 6.2 0 0 1 3.6-1.1c1 0 2 .3 3.2 1 1.6.7 2.6 1.7 3.1 2.8a5 5 0 0 1 .3 3.5l-2.7-.8a3 3 0 0 0-.2-2c-.3-.6-.8-1-1.6-1.4a3.8 3.8 0 0 0-3.1-.3c-1 .3-1.9 1.2-2.6 2.6-.7 1.4-1 2.7-.7 3.8a3.7 3.7 0 0 0 2 2.4c.5.3 1.1.5 1.7.5a6 6 0 0 0 1.8 0l.8-1.6-2.9-1.5zm-90.2-22.3 2-14 4.2.7 1.1 9.8 3.9-9 4.2.6-2 13.8-2.7-.4 1.7-10.9-4.4 10.5-2.7-.4-1.1-11.3-1.6 11-2.6-.4zm-14.1-1.7 1.3-14 10.3 1-.2 2.4-7.5-.7-.3 3 7 .7-.3 2.4-7-.7-.3 3.8 7.8.7-.2 2.4-10.6-1z"
        />
        <g strokeOpacity=".5">
          <path
            fill="#309e3a"
            d="M216.5 191.3c0-1.5.3-2.6.7-3.6a6.7 6.7 0 0 1 1.4-1.9 5.4 5.4 0 0 1 1.8-1.2c1-.3 2-.5 3-.5 2.1 0 3.7.8 5 2a7.4 7.4 0 0 1 1.6 5.5c0 2.2-.7 4-2 5.3a6.5 6.5 0 0 1-5 1.7 6.6 6.6 0 0 1-4.8-2 7.3 7.3 0 0 1-1.7-5.3z"
          />
          <path
            fill="#f7ffff"
            d="M219.4 191.3c0 1.5.3 2.7 1 3.6.7.8 1.6 1.3 2.8 1.3a3.5 3.5 0 0 0 2.8-1.1c.7-.8 1-2 1.1-3.7 0-1.6-.2-2.8-1-3.6a3.5 3.5 0 0 0-2.7-1.3 3.6 3.6 0 0 0-2.8 1.2c-.8.8-1.1 2-1.2 3.6z"
          />
        </g>
        <g strokeOpacity=".5">
          <path
            fill="#309e3a"
            d="m233 198.5.2-14h6c1.5 0 2.5.2 3.2.5.7.2 1.2.7 1.6 1.3s.6 1.4.6 2.3a3.8 3.8 0 0 1-1 2.6 4.5 4.5 0 0 1-2.7 1.2l1.5 1.2c.4.4.9 1.2 1.5 2.3l1.7 2.8h-3.4l-2-3.2-1.4-2a2.1 2.1 0 0 0-.9-.6 5 5 0 0 0-1.4-.2h-.6v5.8H233z"
          />
          <path
            fill="#fff"
            d="M236 190.5h2c1.4 0 2.3 0 2.6-.2.3 0 .6-.3.8-.5s.3-.7.3-1c0-.6-.1-1-.4-1.2-.2-.3-.6-.5-1-.6h-2l-2.3-.1v3.5z"
          />
        </g>
        <g strokeOpacity=".5">
          <path
            fill="#309e3a"
            d="m249 185.2 5.2.3c1.1 0 2 .1 2.6.3a4.7 4.7 0 0 1 2 1.4 6 6 0 0 1 1.2 2.4c.3.9.4 2 .3 3.3a9.3 9.3 0 0 1-.5 3c-.4 1-1 1.8-1.7 2.4a5 5 0 0 1-2 1c-.6.2-1.5.2-2.5.2l-5.3-.3.7-14z"
          />
          <path
            fill="#fff"
            d="m251.7 187.7-.5 9.3h3.8c.5 0 .9-.2 1.2-.5.3-.3.6-.7.8-1.3.2-.6.4-1.5.4-2.6l-.1-2.5a3.2 3.2 0 0 0-.8-1.4 2.7 2.7 0 0 0-1.2-.7 13 13 0 0 0-2.3-.3h-1.3z"
          />
        </g>
        <g strokeOpacity=".5">
          <path
            fill="#309e3a"
            d="m317.6 210.2 3.3-13.6 4.4 1 3.2 1c.7.4 1.3 1 1.6 1.9.4.8.4 1.7.2 2.8-.2.8-.5 1.5-1 2a3.9 3.9 0 0 1-3 1.4c-.7 0-1.7-.2-3-.5l-1.7-.5-1.2 5.2-2.8-.7z"
          />
          <path
            fill="#fff"
            d="m323 199.6-.8 3.8 1.5.4c1 .2 1.8.4 2.2.3a1.9 1.9 0 0 0 1.6-1.5c0-.5 0-.9-.2-1.3a2 2 0 0 0-1-.9l-1.9-.5-1.3-.3z"
          />
        </g>
        <g strokeOpacity=".5">
          <path
            fill="#309e3a"
            d="m330.6 214.1 4.7-13.2 5.5 2c1.5.5 2.4 1 3 1.4.5.5.9 1 1 1.8s.2 1.5 0 2.3c-.4 1-1 1.7-1.8 2.2-.8.4-1.8.5-3 .3.4.5.8 1 1 1.6l.8 2.7.6 3.1-3.1-1.1-1-3.6a19.5 19.5 0 0 0-.7-2.4 2.1 2.1 0 0 0-.6-.8c-.2-.3-.6-.5-1.3-.7l-.5-.2-2 5.6-2.6-1z"
          />
          <path
            fill="#fff"
            d="m336 207.4 1.9.7c1.3.5 2.1.7 2.5.7.3 0 .6 0 .9-.3.3-.2.5-.5.6-.9.2-.4.2-.8 0-1.2a1.7 1.7 0 0 0-.8-.9l-2-.7-2-.7-1.2 3.3z"
          />
        </g>
        <g strokeOpacity=".5">
          <path
            fill="#309e3a"
            d="M347 213.6a9 9 0 0 1 1.7-3.2 6.6 6.6 0 0 1 1.8-1.5 6 6 0 0 1 2-.7c1 0 2 0 3.1.4a6.5 6.5 0 0 1 4.2 3.3c.8 1.6.8 3.5.2 5.7a7.4 7.4 0 0 1-3.4 4.5c-1.5.9-3.3 1-5.2.4a6.6 6.6 0 0 1-4.2-3.3 7.3 7.3 0 0 1-.2-5.6z"
          />
          <path
            fill="#fff"
            d="M349.8 214.4c-.4 1.5-.5 2.8 0 3.8s1.2 1.6 2.3 2c1 .3 2 .2 3-.4 1-.5 1.6-1.6 2.1-3.2.5-1.5.5-2.7 0-3.7a3.5 3.5 0 0 0-2.2-2 3.6 3.6 0 0 0-3 .3c-1 .6-1.7 1.6-2.2 3.2z"
          />
        </g>
        <g strokeOpacity=".5">
          <path
            fill="#309e3a"
            d="m374.3 233.1 6.4-12.4 5.3 2.7a10 10 0 0 1 2.7 1.9c.5.5.8 1.1.8 1.9s0 1.5-.4 2.2a3.8 3.8 0 0 1-2 2c-1 .2-2 .2-3.1-.2.4.6.6 1.2.8 1.7.2.6.3 1.5.4 2.8l.2 3.2-3-1.5-.4-3.7a20 20 0 0 0-.3-2.5 2 2 0 0 0-.5-1l-1.2-.7-.5-.3-2.7 5.2-2.5-1.3z"
          />
          <path
            fill="#fff"
            d="m380.5 227.2 1.9 1c1.2.6 2 1 2.3 1 .3 0 .7 0 1-.2.3-.1.5-.4.7-.8.2-.4.3-.8.2-1.2a2 2 0 0 0-.7-1 23.7 23.7 0 0 0-1.8-1l-2-1-1.6 3.2z"
          />
        </g>
        <g strokeOpacity=".5">
          <path
            fill="#309e3a"
            d="M426.1 258.7a8.9 8.9 0 0 1 2.5-2.6 6.6 6.6 0 0 1 2.2-.9 5.5 5.5 0 0 1 2.2 0c1 .2 1.9.6 2.8 1.2a6.6 6.6 0 0 1 3 4.4c.3 1.7-.2 3.6-1.4 5.5a7.3 7.3 0 0 1-4.5 3.3 6.5 6.5 0 0 1-5.2-1.1 6.6 6.6 0 0 1-3-4.4c-.3-1.8.2-3.6 1.4-5.4z"
          />
          <path
            fill="#fff"
            d="M428.6 260.3c-1 1.3-1.3 2.5-1.1 3.6a3.6 3.6 0 0 0 1.6 2.5c1 .7 2 .9 3 .6 1-.3 2-1 2.9-2.4.9-1.4 1.3-2.6 1.1-3.6-.1-1-.7-1.9-1.6-2.6s-2-.8-3-.5c-1 .2-2 1-3 2.4z"
          />
        </g>
        <path
          fill="#309e3a"
          d="m301.8 204.5 2.3-9.8 7.2 1.7-.3 1.6-5.3-1.2-.5 2.2 4.9 1.1-.4 1.7-4.9-1.2-.6 2.7 5.5 1.3-.4 1.6-7.5-1.7z"
        />
      </g>
    </svg>
  );
}

export function UnitedStatesFlag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-us" viewBox="0 0 640 480">
      <path fill="#bd3d44" d="M0 0h640v480H0" />
      <path
        stroke="#fff"
        strokeWidth="37"
        d="M0 55.3h640M0 129h640M0 203h640M0 277h640M0 351h640M0 425h640"
      />
      <path fill="#192f5d" d="M0 0h364.8v258.5H0" />
      <marker id="us-a" markerHeight="30" markerWidth="30">
        <path fill="#fff" d="m14 0 9 27L0 10h28L5 27z" />
      </marker>
      <path
        fill="none"
        markerMid="url(#us-a)"
        d="m0 0 16 11h61 61 61 61 60L47 37h61 61 60 61L16 63h61 61 61 61 60L47 89h61 61 60 61L16 115h61 61 61 61 60L47 141h61 61 60 61L16 166h61 61 61 61 60L47 192h61 61 60 61L16 218h61 61 61 61 60L0 0"
      />
    </svg>
  );
}
