import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { ListItemIcon, ListItemText, Menu, MenuItem, TextField } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import { Button } from 'components/base/Button';
import { Typography } from 'components/base/Typography';

import { RoleHash } from 'utils/shared/roleHash';
import { LocalStorage, SOTER_STORAGE_KEY } from 'utils/storage/localStorage';

import { UserRoles } from 'domains/user';

import { BrazilFlag, Logout, More, UnitedStatesFlag } from 'assets/icons';
import { Logo } from 'assets/images';

import { theme } from '../../../styles/theme';

import './styles/styles.css';

const primaryDark = theme.palette.primary.dark;
const secondaryColor = theme.palette.secondary.main;
const fontWeight = '700';

export function Header({ name, activePage }: { name?: string; activePage: string }): JSX.Element {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();

  const { user } = useAuth();
  const goTo = path => {
    navigate(path);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    auth.signoutRedirect();
    auth.removeUser();
  };

  function handleChangeLanguage(newLanguage: string) {
    LocalStorage.setValue(`${SOTER_STORAGE_KEY}:Language`, newLanguage.toString(), true);
    i18n.changeLanguage(newLanguage);
  }

  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{
        boxShadow:
          '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
        borderBottom: '2.5px solid',
        borderImage: `linear-gradient(to right, ${primaryDark} 30%, ${secondaryColor} 20%)`,
        borderImageSlice: '1',
      }}
    >
      <Container sx={{ width: '100%', maxWidth: '100% !important' }}>
        <Toolbar disableGutters>
          <Box
            display="flex"
            sx={{ cursor: 'pointer' }}
            role="button"
            onClick={() => goTo('/')}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === 'Space') {
                goTo('/');
              }
            }}
            tabIndex={0}
          >
            <Logo />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }} />
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: 'none', md: 'flex', justifyContent: 'end' },
              width: '30%',
            }}
          >
            {user && RoleHash.verifyRole(UserRoles.Buyer) && (
              <>
                <Button
                  onClick={() => goTo('/orderRegister')}
                  color={activePage === 'orderRegister' ? primaryDark : '#FF8200'}
                  fW={activePage === 'orderRegister' ? fontWeight : '600'}
                  mxW="max-content"
                  tT="uppercase"
                  mT={0}
                >
                  {t('components.header.new_order')}
                </Button>

                <Button
                  onClick={() => goTo('/')}
                  color={activePage === 'home' ? primaryDark : '#000000DE'}
                  fW={activePage === 'home' ? fontWeight : '400'}
                  mxW="max-content"
                  tT="uppercase"
                  mT={0}
                >
                  {t('components.header.my_orders')}
                </Button>
              </>
            )}

            {user && RoleHash.verifyRole(UserRoles.Analyst) && (
              <Button
                onClick={() => goTo('/')}
                color={activePage === 'home' ? primaryDark : '#000000DE'}
                fW={activePage === 'home' ? fontWeight : '400'}
                mxW="max-content"
                tT="uppercase"
                mT={0}
              >
                {t('components.header.order_management')}
              </Button>
            )}

            {user && RoleHash.verifyRole(UserRoles.Admin) && (
              <>
                <Button
                  onClick={() => goTo('/admin')}
                  color={activePage === 'admin' ? primaryDark : '#000000DE'}
                  fW={activePage === 'admin' ? fontWeight : '400'}
                  mxW="max-content"
                  tT="uppercase"
                  mT={0}
                >
                  {t('components.header.home')}
                </Button>
                <Button
                  onClick={() => goTo('/')}
                  color={activePage === 'home' ? primaryDark : '#000000DE'}
                  fW={activePage === 'home' ? fontWeight : '400'}
                  mxW="max-content"
                  tT="uppercase"
                  mT={0}
                >
                  {t('components.header.order_management')}
                </Button>
              </>
            )}
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              marginLeft: '20px',
              display: 'flex',
              flexDirection: 'row',
              width: '500px',
            }}
          >
            <IconButton sx={{ p: 0 }}>
              <Avatar />
            </IconButton>

            <Box
              sx={{
                flexGrow: 1,
                display: {
                  display: 'flex',
                  marginLeft: '16px',
                  alignItems: 'center',
                },
              }}
            >
              <Typography
                variant="button"
                display="block"
                tT="initial"
                fS="14px"
                fW="700"
                lH="150%"
                lS="0.15px"
              >
                {name || ''}
              </Typography>

              <TextField
                select
                id="select-language"
                value={i18n.language}
                label="Language"
                size="small"
                sx={{ ml: '16px' }}
                SelectProps={{
                  sx: {
                    minWidth: '170px',
                    maxHeight: '40px',
                  },
                }}
                onChange={event => handleChangeLanguage(event.target.value)}
              >
                <MenuItem value="pt-BR">
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ListItemText>Português</ListItemText>
                    <ListItemIcon sx={{ maxHeight: '20px' }}>
                      <BrazilFlag />
                    </ListItemIcon>
                  </div>
                </MenuItem>
                <MenuItem value="en-US">
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ListItemText>Inglês</ListItemText>
                    <ListItemIcon sx={{ maxHeight: '20px' }}>
                      <UnitedStatesFlag />
                    </ListItemIcon>
                  </div>
                </MenuItem>
              </TextField>

              <Box
                onClick={e => handleMenu(e)}
                display="flex"
                padding="12px"
                sx={{ cursor: 'pointer' }}
              >
                {More()}
              </Box>
              <Menu
                className="menuLogout"
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => logout()} sx={{ padding: '8px 16px' }}>
                  {Logout()}
                  <span className="SpanMenuItem"> {t('components.header.logout')}</span>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
